.Status {
    display: grid;
    grid-template-columns: 30% auto;
    justify-items: center;
    align-items: center;
    text-shadow: 1px 1px #000000;
}

.pfpPartnerUser {
    border-radius: 100%;
    border: 3px solid #0000003e;
    max-width: 20vw;
    width: 100%;
    height: auto;
    margin-bottom: 15%;
}

.alert-primary {
    background-color: #101828;
    border: #101828;
    padding: 12px 20px;
    border-radius: 10px;
}

.alert-heading {
    font-size: 18px;
    font-weight: 700;
    color: #FFF;
}

.alert-primary p {
    font-size: 15px;
    font-weight: 500;
    color: #FFF;
    margin: 0;
}