
.MemoryForm {
    text-shadow: 2px 2px #000000;
    color: #FFF;
    text-align: center;
    margin: 8vh 1% 0 1%;
}


.MemoryForm form div {
    margin: 0 10vw 8% 10vw;
}

.uploadText h1, .summaryText h1 {
    color: #FEDA14;
    font-size: 28px;
    font-weight: 800;
}

/* .summaryText, #summaryTextarea, .saveMemoryBtn, .changeImgBtn {
    display: none;
} */

#summaryTextarea {
    resize: none;
}