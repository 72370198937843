
.MemoriesContent {
    text-align: center;
    margin: 2vh 1% 2vh 1%;
}

.MemoriesContent h1 {
    color: #FEDA14;
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 5%;
}


.MemoriesContent .footer {
    color: #c9cfd1;
    font-size: 15px;
    font-weight: 700;
    margin: 3% 0 3% 0;
}

.MemoriesContent .card {
    background-color: #424141;
}

.MemoriesContent .card-title {
    color: #FEDA14;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 5%;
}

.MemoriesContent .card-text {
    color: #ffffff;
    font-size: 15px;
}

.MemoriesContent .card .card-footer {
    background-color: #FEDA14 !important;
}