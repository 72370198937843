
button:disabled,
button[disabled] {
  cursor: not-allowed !important;
}

.NavBar {
    display: grid;
    grid-template-columns: 40% 70%;
    justify-items: center;
    align-items: center;
    margin-top: 5vh;
}

.pfpMainUser {
    border-radius: 100%;
    border: 3px solid #0000003e;
    max-width: 20vw;
    width: 100%;
    height: auto;
}