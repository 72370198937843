.Content {
    margin-bottom: 18vh;
    text-shadow: 2px 2px #000000;
    color: #FFF;
}

.countdown {
    margin-top: 15vh;
    text-align: center;
    color: #FEDA14;
}

.countdown h1 {
    color: #FEDA14;
    font-weight: 700;
    font-size: 5em;
}

.countdown h3 {
    font-size: 1.5em;
}

.countdownFinished {
    margin-top: 20vh;
    text-align: center;
}

.countdownFinished h1 {
    color: #FEDA14;
    font-size: 2.5em;
    font-weight: 900;
    text-transform: uppercase;
}

.countdownText {
    text-align: center;
    
}

.countdownText .before {
    font-size: 25px;
    font-weight: 700;
    
}

.countdownText .after {
    font-size: 15px;
    font-weight: 500;
}

.mainFeatureBtn {
    text-align: center;
    justify-content: center;
}

.mainFeatureBtn button {
    font-weight: 700;
    font-size: 1.5rem;
    padding: 15px 25px;
    border-radius: 10px;
    border: 2px solid #101828;
    box-shadow: 2px 2px #0000008a;
    margin-top: 2vh;
}

.notReady {
    color: #707070 !important;
    background-color: #abacac !important;
    border-color: #abacac !important;
}