
.Login {
    margin-top: 35vh;
}

.Login form {
    text-align: center;
    justify-content: center;
    align-items: center;
}

.Login form button {
    font-size: 25px;
    padding: 8px 20px;
    margin-top: 5%;
    border-radius: 15px;
    border: 2px solid #3b3939;
}

.Login h1 {
    color: #FEDA14;
    font-size: 28px;
    font-weight: 800;
}